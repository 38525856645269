import GLOBAL_ACTIVITIES from './db-activities.json';
import GLOBAL_COUNTRIES from './db-countries.json';
import GLOBAL_LEGALSIZES from './db-legalsizes.json';
import GLOBAL_SPECIES from './db-species.json';
import GLOBAL_CATEGORIES from './db-categories.json';
import GLOBAL_KPIS from './db-kpis.json';
import GLOBAL_KPI_TYPES from './db-kpitypes.json';

function getElement(db, id) {
    for (let i = 0; i < db.length; i++) {
        const elem = db[i];
        if (elem.id == id)
            return elem;
    }

    return {};
}

function getKpiTypes(typeId, ...typeIds) {
    const target = typeIds.length == 0 ? typeId : [typeId, ...typeIds];
    
    if (target instanceof Array) {
        return GLOBAL_KPI_TYPES.filter(obj => target.indexOf(obj.id) != -1);
    } else {
        for (let i = 0; i < GLOBAL_KPI_TYPES.length; i++) {
            const elem = GLOBAL_KPI_TYPES[i];
            if (elem.id == target)
                return elem;
        }
    }
}

function getCountryName(countryId) {
    if (typeof countryId == "number" )
        return getElement(GLOBAL_COUNTRIES, countryId).name;

    for (let i = 0; i < GLOBAL_COUNTRIES.length; i++) {
        const elem = GLOBAL_COUNTRIES[i];
        if (elem.isoCode == countryId) //Compare isoCode
            return elem.name;
    }
}

function getActivityName(activityId) {
    const o = getElement(GLOBAL_ACTIVITIES, activityId)
    return o == undefined ? undefined : o.name;
}

function getSpecieName(specieId) {
    const o = getElement(GLOBAL_SPECIES, specieId);
    return o == undefined ? undefined : o.name;
}

function getLegalSizeName(legalsizeId) {
    const o = getElement(GLOBAL_LEGALSIZES, legalsizeId);
    return o == undefined ? undefined : o.name;
}

function getCategoryName(categoryId) {
    const o = getElement(GLOBAL_CATEGORIES, categoryId);
    return o == undefined ? undefined : o.name;
}

function getActivitiesString(activitiesArray) {
    let strings = []
    activitiesArray.forEach(obj => {
        const id = obj.activityId;
        let str = getElement(GLOBAL_ACTIVITIES, id).name;
        if (strings.indexOf(str) == -1)
            strings.push(str);
    });

    return strings.join(', ');
}

function getKpisFromType(typeId) {
    return GLOBAL_KPIS.filter(obj => obj.typeId == typeId);
}

function getKpi(kpiId, ...kpiIdS) {
    const target = kpiIdS.length == 0 ? kpiId : [kpiId, ...kpiIdS];
    
    if (target instanceof Array) {
        return GLOBAL_KPIS.filter(obj => target.indexOf(obj.kpiId) != -1);
    } else {
        for (let i = 0; i < GLOBAL_KPIS.length; i++) {
            const elem = GLOBAL_KPIS[i];
            if (elem.kpiId == kpiId)
                return elem;
        }
    }
}

function getKpiOperations(kpiId) {
    for (let i = 0; i < GLOBAL_KPIS.length; i++) {
        const elem = GLOBAL_KPIS[i];
        if (elem.kpiId == kpiId)
            return {
                specieOperation: elem.config.specieOperation,
                activityOperation: elem.config.activityOperation,
                facilityOperation: elem.config.facilityOperation
            };
    }
}

function getSpeciesString(speciesArray) {
    let strings = []
    speciesArray.forEach(obj => {
        const id = obj.specieId;
        let str = getElement(GLOBAL_SPECIES, id).name;
        if (strings.indexOf(str) == -1)
            strings.push(str);
    });

    return strings.join(', ');
}

function getCountryIso(countryId) {
    const o = getElement(GLOBAL_COUNTRIES, countryId);
    return o == undefined ? undefined : o.isoCode;
}

export {
    getCountryName,
    getActivityName,
    getSpecieName,
    getLegalSizeName,
    getCategoryName,
    getActivitiesString,
    getSpeciesString,
    getKpisFromType,
    getKpi,
    getKpiTypes,
    getKpiOperations,
    getCountryIso
};